<template>
  <v-card>
    <v-card-text>
      <v-form v-model="isValid">
        <v-row>
          <v-col cols="12">
            <v-textarea :label="$_.capitalize($t('main.freight_description')) + '*'" v-model="cargo" :rules="[$rules.required]" :hint="$t('texts[0]')" persistent-hint></v-textarea>
          </v-col>
          <v-col cols="12">
            <v-textarea :label="$t('main.request_notes')" v-model="notice"></v-textarea>
          </v-col>
          <v-col cols="12">
            <v-text-field :label="$_.upperFirst($t('main.invoice_reference'))" v-model="billingNotice"></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'InquiryNotice',
  props: {
    step: {
      type: Number,
      default: 3
    }
  },
  data () {
    return {
      isValid: false,
      cargo: '',
      notice: '',
      billingNotice: ''
    }
  },
  watch: {
    isValid: {
      handler (n) {
        if (n) {
          this.$emit('complete', this.step)
        } else {
          this.$emit('uncomplete', this.step)
        }
      }
    }
  },
  mounted () {
    const inquiry = this.$store.getters.inquiry
    this.notice = inquiry.notice ? inquiry.notice : ''
    this.cargo = inquiry.cargo ? inquiry.cargo : ''
    this.billingNotice = inquiry.billingNotice ? inquiry.billingNotice : ''
  },
  methods: {
    init() {
      this.$emit(this.isValid ? 'complete' : 'uncomplete', 3)
    },
    saveToStore() {
      this.$store.commit('setInquiryValue', { key: 'notice', value: this.notice })
      this.$store.commit('setInquiryValue', { key: 'cargo', value: this.cargo })
      this.$store.commit('setInquiryValue', { key: 'billingNotice', value: this.billingNotice })
    }
  }
}
</script>

