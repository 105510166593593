<template>
  <v-form>
    <v-row>
      <v-col cols="12" class="subtitle-1" v-html="$_.upperFirst($t('main.pickup_and_delivery')) + ' ' + $tc('main.time', 2)">

      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-select :label="$t('main.time_period')" :items="computedExecutionTimes" v-model="executionTime"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class=" d-flex d-sm-none align-center">
        <span class="subtitle-1" v-html="$_.capitalize($t('main.pickup'))" />
      </v-col>
      <v-col cols="12" md="3">
        <v-menu :close-on-content-click="true" transition="scale-transition" min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="computedPickupDateFormatted"
                :label="$t('main.pickup') + ' ' + $t('main.from_time')"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :rules="[$rules.required]"
            ></v-text-field>
          </template>
          <v-date-picker
              :locale="calendarLocale"
              first-day-of-week="1"
              v-model="pickup"
              :min="minDate"
              :allowed-dates="allowedDates"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="6">
        <v-select :label="($t('main.time_window')) " :items="computedExecutionTimewindows" v-model="pickupExecutionTimewindow"/>
      </v-col>
      <v-col cols="12" md="3">
        <div v-if="!pickupHasFixedTime" class="d-flex ">
          <v-icon class="mr-3">mdi-clock-outline</v-icon>
          <v-select :items="hours" v-model="pickupTimeFrom" :label="$t('main.from_time')"/>
          <v-icon class="mx-2">mdi-minus</v-icon>
          <v-select :items="hours" v-model="pickupTimeTo" :label="$t('main.until')"/>
        </div>

        <v-dialog
            v-if="pickupHasFixedTime"
            ref="timePickerStartDialog"
            v-model="timePickerStart"
            :return-value.sync="pickupTime"
            width="440px"
            height="540px"
            persistent

        >
          <template v-slot:activator="{ on }">
            <v-text-field
                v-model="pickupTime"
                :label="$t('main.time')"
                prepend-icon="mdi-clock-outline"
                readonly
                v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
              v-if="timePickerStart"
              v-model="pickupTime"
              full-width
              landscape
              ampm-in-title
              format="24hr"
              :allowed-minutes="[0, 15, 30, 45]"
              color="primary"
              header-color="grey"
          >
          </v-time-picker>
          <v-spacer></v-spacer>
          <v-btn block tile color="primary" @click="$refs.timePickerStartDialog.save(pickupTime)">OK</v-btn>
        </v-dialog>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class=" d-flex d-sm-none align-center">
        <span class="subtitle-1">{{$_.capitalize($t('main.delivery')) }}</span>
      </v-col>
      <v-col cols="12" md="3">
        <v-menu :close-on-content-click="true" transition="scale-transition" min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="computedDeliveryDateFormatted"
                :label="$t('main.delivery') + ' ' + $t('main.until')"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :rules="[$rules.required]"
            ></v-text-field>
          </template>
          <v-date-picker
              :locale="calendarLocale"
              first-day-of-week="1"
              v-model="delivery"
              :min="minDateDelivery"
              :allowed-dates="allowedDates"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="6">
        <v-select :label="($t('main.time_window')) " :items="computedExecutionTimewindows" v-model="deliveryExecutionTimewindow"/>
      </v-col>
      <v-col cols="12" md="3">
        <div v-if="!deliveryHasFixedTime" class="d-flex ">
          <v-icon class="mr-3">mdi-clock-outline</v-icon>
          <v-select :items="hours" v-model="deliveryTimeFrom" :label="$t('main.from_time')"/>
          <v-icon class="mx-2">mdi-minus</v-icon>
          <v-select :items="hours" v-model="deliveryTimeTo" :label="$t('main.until')"/>
        </div>
        <v-dialog
            v-if="deliveryHasFixedTime"
            ref="timePickerStartDialog"
            v-model="timePickerEnd"
            :return-value.sync="deliveryTime"
            width="440px"
            height="540px"
            persistent

        >
          <template v-slot:activator="{ on }">
            <v-text-field
                v-model="deliveryTime"
                :label="$t('main.time')"
                prepend-icon="mdi-clock-outline"
                readonly
                v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
              v-if="timePickerEnd"
              v-model="deliveryTime"
              full-width
              landscape
              ampm-in-title
              format="24hr"
              :allowed-minutes="[0, 15, 30, 45]"
              color="primary"
              header-color="grey"
          >
          </v-time-picker>
          <v-spacer></v-spacer>
          <v-btn block tile color="primary" @click="$refs.timePickerStartDialog.save(deliveryTime)">OK</v-btn>
        </v-dialog>
      </v-col>
    </v-row>

  </v-form>

</template>

<script>
import moment from 'moment-business-days'

export default {
  name: 'InquiryLoadingTimes',
  props: {
    step: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      executionTimes: [],
      executionTimewindows: [],
      executionTime: 0,
      pickupExecutionTimewindow: 0,
      deliveryExecutionTimewindow: 0,
      currentExecutionTime: {},
      currentPickupExecutionTimewindow: {},
      currentDeliveryExecutionTimewindow: {},
      pickup: null,
      delivery: null,
      pickupTime: '08:00',
      pickupTimeFrom: '08:00',
      pickupTimeTo: '18:00',
      deliveryTime: '18:00',
      deliveryTimeFrom: '08:00',
      deliveryTimeTo: '18:00',
      workingWeekdays: [1, 2, 3, 4, 5],
      timePickerStart: false,
      timePickerEnd: false
    }
  },
  watch: {
    executionTime () {
      this.currentExecutionTime = this.$_.find(this.executionTimes, item => {
        return item.id_addon == this.executionTime
      })
      if (this.currentExecutionTime.extended_data) {
        const j = JSON.parse(this.currentExecutionTime.extended_data)
        if (j.working_weekdays) {
          this.workingWeekdays = j.working_weekdays.split(',').map(item => {
            return parseInt(item)
          })
        }
      }
      moment.updateLocale('de', { workingWeekdays: this.workingWeekdays })
      this.setDeliveryDate()
    },
    pickupExecutionTimewindow () {
      this.currentPickupExecutionTimewindow = this.$_.find(this.executionTimewindows, item => {
        return item.id_addon == this.pickupExecutionTimewindow
      })
    },
    deliveryExecutionTimewindow () {
      this.currentDeliveryExecutionTimewindow = this.$_.find(this.executionTimewindows, item => {
        return item.id_addon == this.deliveryExecutionTimewindow
      })
    },
    pickup () {
      this.setDeliveryDate()
    }
  },
  computed: {
    calendarLocale() {
      if(this.$root.$i18n.locale === 'en') {
        return 'en-EN'
      }
      return 'de-de'
    },
    hours () {
      let h = []
      for (let i = 6; i <= 22; i++) {
        h.push(this.$_.padStart(i, 2, '0') + ':00')
      }
      return h
    },
    minDate () {
      return moment().nextBusinessDay().format('YYYY-MM-DD')
    },
    minDateDelivery () {
      return moment(this.delivery).format('YYYY-MM-DD')
    },
    computedPickupDateFormatted () {
      if (!this.pickup) return null
      const [year, month, day] = this.pickup.split('-')
      return `${day}.${month}.${year}`
    },
    computedDeliveryDateFormatted () {
      if (!this.delivery) return null
      const [year, month, day] = this.delivery.split('-')
      return `${day}.${month}.${year}`
    },
    computedExecutionTimes () {
      let c = []
      this.$_.forEach(this.executionTimes, item => {
        c.push({
          text: this.$root.$i18n.locale === 'en' ? item.name_en : item.name,
          value: item.id_addon
        })
      })
      return c
    },
    computedExecutionTimewindows () {
      let c = []
      this.$_.forEach(this.executionTimewindows, item => {
        c.push({
          text: this.$root.$i18n.locale === 'en' ? item.name_en : item.name,
          value: item.id_addon
        })
      })
      return c
    },
    pickupHasFixedTime () {
      try {
        const x = Object.assign({}, this.currentPickupExecutionTimewindow)
        const j = parseFloat(JSON.parse(x.extended_data).variance)
        return j != 24
      } catch (e) {
        return true
      }

    },
    deliveryHasFixedTime () {
      try {
        const x = Object.assign({}, this.currentDeliveryExecutionTimewindow)
        const j = parseFloat(JSON.parse(x.extended_data).variance)
        return j != 24
      } catch (e) {
        return true
      }
    }
  },
  mounted () {
    moment.locale('de')
    this.init2()
    this.pickup = moment().nextBusinessDay().format('YYYY-MM-DD')
  },
  methods: {
    init2 () {
      this.executionTimes = this.$_.filter(this.$store.getters.addons, addon => {
        return addon.to_group == 'execution_time'
      })
      this.executionTime = parseInt(this.executionTimes[0].id_addon)

      this.executionTimewindows = this.$_.filter(this.$store.getters.addons, addon => {
        return addon.to_group == 'execution_timewindow'
      })
      this.pickupExecutionTimewindow = parseInt(this.executionTimewindows[0].id_addon)
      this.deliveryExecutionTimewindow = parseInt(this.executionTimewindows[0].id_addon)
    },
    setDeliveryDate () {
      let daysToAdd = 1
      if (this.currentExecutionTime.extended_data) {
        const j = JSON.parse(this.currentExecutionTime.extended_data)
        daysToAdd = j.days_add
      }
      this.delivery = moment(this.pickup).businessAdd(daysToAdd).format('YYYY-MM-DD')
    },
    allowedDates (val) {
      return this.workingWeekdays.indexOf(moment(val).day()) > -1 && moment(val).isBusinessDay()
    },
    saveToStore () {
      const data = {
        executionTime: this.currentExecutionTime,
        pickupExecutionTimewindow: this.currentPickupExecutionTimewindow,
        deliveryExecutionTimewindow: this.currentDeliveryExecutionTimewindow,
        pickup: this.pickup,
        delivery: this.delivery,
        pickupTime: this.pickupHasFixedTime ? this.pickupTime : this.pickupTimeFrom + '-' + this.pickupTimeTo,
        deliveryTime: this.deliveryHasFixedTime ? this.deliveryTime : this.deliveryTimeFrom + '-' + this.deliveryTimeTo
      }
      this.$store.commit('setInquiryPickupDelivery', data)
    }
  }
}
</script>

