<template>
  <div>
    <v-row>
      <v-col cols="12" md="3" class="d-flex align-md-center">
        {{$t('main.loading_options')}}
      </v-col>
      <v-col cols="12" md="7" class="d-flex align-center">
        <v-btn-toggle v-model="loading" multiple color="primary">
          <v-btn :disabled="loadingIsUnknown" tabindex="-1" v-for="option in loadingOptionsBtn" :key="option.value" :value="option.value" height="50px">
            <component :is="option.name" width="52px" height="40px" class="pr-3"></component>
            <span class="hidden-sm-and-down">{{ option.text }}</span>
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col cols="12" md="2" class="d-flex align-center">
        <v-switch v-model="loadingIsUnknown" :label="$t('loadingOptions.Unbekannt')"></v-switch>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="3" class="d-flex align-center">
        {{$t('main.unloading_options')}}
      </v-col>
      <v-col cols="12" md="7" class="d-flex align-center">
        <v-btn-toggle v-model="unloading" multiple color="primary">
          <v-btn :disabled="unloadingIsUnknown" tabindex="-1" v-for="option in loadingOptionsBtn" :key="option.value" :value="option.value" height="50px">
            <component :is="option.name" width="52px" height="40px" class="pr-3"></component>
            <span class="hidden-sm-and-down">{{ option.text }}</span>
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col cols="12" md="2" class="d-flex align-center">
        <v-switch v-model="unloadingIsUnknown" :label="$t('loadingOptions.Unbekannt')"></v-switch>
      </v-col>
    </v-row>

  </div>
</template>

<script>


import IconLoadingOben from '@/_shared/svg/IconLoadingOben'
import IconLoadingHinten from '@/_shared/svg/IconLoadingHinten'
import IconLoadingSeitlich from '@/_shared/svg/IconLoadingSeitlich'

export default {
  name: 'Loading',
  components: { IconLoadingHinten, IconLoadingSeitlich, IconLoadingOben },
  data () {
    return {
      unloadingIsUnknown: false,
      loadingIsUnknown: false,
      loading: [],
      unloading: [],
      options: [
        {
          text: 'Hinten', value: 1, name: 'LoadingBehind'
        },
        {
          text: 'Seitlich', value: 2, name: 'LoadingSide'
        },
        {
          text: 'Oben', value: 4, name: 'LoadingTop'
        }
      ]
    }
  },
  computed: {
    isComplete () {
      return (this.$_.sum(this.loading) > 0 && this.$_.sum(this.unloading) > 0)
          || (this.$_.sum(this.loading) > 0 && this.unloadingIsUnknown)
          || (this.$_.sum(this.unloading) > 0 && this.loadingIsUnknown)
          || (this.unloadingIsUnknown && this.loadingIsUnknown)
    },
    loadingOptionsBtn () {
      return this.$_.compact(this.$store.getters.config.loadingOptions.map((item, index) => {
        return item.indexOf(' ') > -1 || index === 0 ? null : { text: this.$t('loadingOptions.' + item), value: index, name: 'IconLoading' + item }
      }))
    },
    _computedLoading () {
      let c = 0
      this.$_.forEach(this.loading, item => {
        c += item
      })
      return c
    },
    _computedUnloading () {
      let c = 0
      this.$_.forEach(this.unloading, item => {
        c += item
      })
      return c
    },
    computedLoadingOptionsPerson () {
      return this.$store.getters.config.loadingOptionsPerson.map((item, index) => {
        return { text: !index ? this.$t('loadingOptionsPerson[0]'): this.$t('loadingOptionsPerson[1]'), value: index }
      })
    },
    computedUnloadingOptionsPerson() {
      return this.$store.getters.config.loadingOptionsPerson.map((item, index) => {
        return { text: !index ? this.$t('loadingOptionsPerson[0]'): this.$t('loadingOptionsPerson[2]'), value: index }
      })
    }
  },
  watch: {
    loading () {
      if (this.isComplete) {
        this.emitComplete()
      } else {
        this.emitUncomplete()
      }
    },
    unloading () {
      if (this.isComplete) {
        this.emitComplete()
      } else {
        this.emitUncomplete()
      }
    },
    loadingIsUnknown (n) {
      if(n) {
        this.loading = []
      }
      if (this.isComplete) {
        this.emitComplete()
      } else {
        this.emitUncomplete()
      }
    },
    unloadingIsUnknown (n) {
      if(n) {
        this.unloading = []
      }
      if (this.isComplete) {
        this.emitComplete()
      } else {
        this.emitUncomplete()
      }
    },
  },
  mounted () {
    const hasSearchInSessionStorage = this.$_.isNumber(this.$store.getters.search.loading)
    if (hasSearchInSessionStorage) {
      const loading = this.$utils.format.intToBin(this.$store.getters.search.loading).split('')
      const unloading = this.$utils.format.intToBin(this.$store.getters.search.unloading).split('')
      const map = [1, 2, 4]
      this.$_.forEach(loading, (item, index) => {
        if (parseInt(item) === 1) {
          this.loading.push(map[index])
        }
      })
      this.$_.forEach(unloading, (item, index) => {
        if (parseInt(item) === 1) {
          this.unloading.push(map[index])
        }
      })

    }
  },
  methods: {
    emitComplete () {
      this.$store.commit('searchSetLoading', { loading: this.$_.sum(this.loading), unloading: this.$_.sum(this.unloading) })
      this.$emit('complete', { loading: this.$_.sum(this.loading), unloading: this.$_.sum(this.unloading), loadingIsUnknown: this.loadingIsUnknown, unloadingIsUnknown: this.unloadingIsUnknown })
    },
    emitUncomplete () {
      this.$emit('uncomplete')
    }
  }
}
</script>
