<template>
  <v-card>
    <v-card-text>
      <v-form>
        <v-row>
          <v-col cols="12">
            <v-select :label="$_.upperFirst($t('main.supplementary_insurance'))" :items="insuranceOptions" v-model="has_insurance" @change="change"></v-select>
          </v-col>
        </v-row>
        <v-row v-if="has_insurance">
          <v-col cols="12" >
            <v-text-field :label="$_.upperFirst($t('main.value_of_goods'))"  :prefix="currency" v-model="insurance_value" :rules="[this.$rules.integer]" @click="insurance_value = ''"></v-text-field>
          </v-col>
          <v-col cols="12" class="mt-4">
            <span class="subtitle-1 text-truncate">{{$_.upperFirst($t('main.supplementary_insurance_notice'))}}</span>
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" v-html="insuranceDisclaimer"></v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'InquiryInsurance',
  props: {
    step: {
      type: Number,
      default: 4
    },
    currency: {
      type: String,
      default: 'EUR'
    }
  },
  data () {
    return {
      insurance_value:0,
      has_insurance: false,
      insuranceItems: [],
    }
  },

  computed: {
    insuranceDisclaimer () {
      return this.$root.$i18n.locale === 'en' ? this.$store.getters.instance.insurance_conditions_en : this.$store.getters.instance.insurance_conditions
    },
    insuranceOptions() {
      return [
        {
          text: this.$t('main.supplementary_insurance_agree'),
          value: true
        },
        {
          text: this.$t('main.supplementary_insurance_disagree'),
          value: false
        }
      ]
    },
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.insurance_value = parseInt(this.$store.getters.inquiry.insurance) || 0
      this.has_insurance = this.insurance_value > 0
    },
    change() {
      if(!this.has_insurance) {
        this.insurance_value = 0
      }
     },
    saveToStore () {
      this.$store.commit('setInquiryValue', { key: 'insurance', value: !this.has_insurance ?  0 : this.insurance_value })
    }
  }
}
</script>

