<template>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 23.04">
    <path fill="#1c5bea"  d="M8.58,4.68V18.34H4.8V4.68H0V1.4H13.37V4.68Z"/>
    <path fill="#1c5bea" d="M22,8.5A2.76,2.76,0,0,0,19.6,9.65a5.18,5.18,0,0,0-.8,3v5.65H15.16v-13H18.8V7a6,6,0,0,1,1.75-1.36,4.75,4.75,0,0,1,2.15-.58l0,3.41Z"/>
    <path fill="#1c5bea"
          d="M35.47,18.34H32.05V16.76a4.29,4.29,0,0,1-3.48,1.77,5.14,5.14,0,0,1-3.47-1.2,4,4,0,0,1-1.41-3.19,3.4,3.4,0,0,1,1.46-3,6.94,6.94,0,0,1,4-1h2.71v-.07c0-1.39-.73-2.09-2.2-2.09a6,6,0,0,0-2,.38,6.48,6.48,0,0,0-1.78.93L24.23,7a9.72,9.72,0,0,1,5.86-1.86A6.05,6.05,0,0,1,34,6.27,4.53,4.53,0,0,1,35.47,10Zm-3.66-5v-.63H29.53c-1.45,0-2.18.45-2.18,1.36a1.24,1.24,0,0,0,.52,1.08,2.52,2.52,0,0,0,1.49.37A2.67,2.67,0,0,0,31.07,15,2,2,0,0,0,31.81,13.37Z"/>
    <path fill="#1c5bea"
          d="M41.81,11.07v7.27H38.18v-13h3.63V6.76a5.08,5.08,0,0,1,3.71-1.67A4.58,4.58,0,0,1,49,6.54a5.36,5.36,0,0,1,1.39,3.88v7.92H46.73V11c0-2-.73-3-2.2-3a2.65,2.65,0,0,0-1.91.79A3.06,3.06,0,0,0,41.81,11.07Z"/>
    <path fill="#1c5bea"
          d="M62.86,14.28a3.82,3.82,0,0,1-1.41,3.09,5.54,5.54,0,0,1-3.6,1.16,9.56,9.56,0,0,1-3-.52A8.94,8.94,0,0,1,52,16.47l1.6-2.27a6.93,6.93,0,0,0,4.33,1.57A2,2,0,0,0,59,15.49a.85.85,0,0,0,.41-.74,1.05,1.05,0,0,0-.57-.84,6.17,6.17,0,0,0-1.65-.73c-.72-.23-1.27-.42-1.65-.58a9.17,9.17,0,0,1-1.29-.71,3,3,0,0,1-1.48-2.72,3.58,3.58,0,0,1,1.45-2.94A5.91,5.91,0,0,1,58,5.09a8.13,8.13,0,0,1,4.6,1.55L61.27,9.06A6,6,0,0,0,57.89,7.9c-1.13,0-1.69.3-1.69.92a.89.89,0,0,0,.54.8,11,11,0,0,0,1.76.68c.83.25,1.41.45,1.76.59a9.34,9.34,0,0,1,1.25.64A3,3,0,0,1,62.86,14.28Z"/>
    <path fill="#1c5bea"
          d="M72.63,5.09A5.44,5.44,0,0,1,76.69,7a6.75,6.75,0,0,1,1.78,4.78,6.87,6.87,0,0,1-1.78,4.82,5.48,5.48,0,0,1-4.17,1.94,4.6,4.6,0,0,1-3.84-1.91V23H65V5.31h3.64V6.76A5.37,5.37,0,0,1,72.63,5.09Zm-4,6.74a3.79,3.79,0,0,0,.88,2.6,2.76,2.76,0,0,0,2.15,1,2.92,2.92,0,0,0,2.2-1,3.62,3.62,0,0,0,1-2.58,3.91,3.91,0,0,0-.92-2.64,2.81,2.81,0,0,0-2.21-1.07,2.74,2.74,0,0,0-2.17,1A4,4,0,0,0,68.63,11.83Z"/>
    <path fill="#1c5bea"
          d="M93.61,11.82a6.44,6.44,0,0,1-2,4.77,7.23,7.23,0,0,1-9.86,0,6.46,6.46,0,0,1-2-4.77,6.5,6.5,0,0,1,2-4.78,7.21,7.21,0,0,1,9.86,0A6.47,6.47,0,0,1,93.61,11.82Zm-10.13,0a3.72,3.72,0,0,0,.91,2.61,3.2,3.2,0,0,0,4.65,0A3.77,3.77,0,0,0,90,11.82,3.79,3.79,0,0,0,89,9.21a3.16,3.16,0,0,0-4.65,0A3.74,3.74,0,0,0,83.48,11.82Z"/>
    <path fill="#1c5bea" d="M102.54,8.5a2.76,2.76,0,0,0-2.42,1.15,5.26,5.26,0,0,0-.8,3v5.65H95.69v-13h3.63V7a5.91,5.91,0,0,1,1.76-1.36,4.68,4.68,0,0,1,2.14-.58l0,3.41Z"/>
    <path fill="#1c5bea"
          d="M109.38,7.92v5.84a1.65,1.65,0,0,0,.38,1.17,1.2,1.2,0,0,0,.92.43,2.09,2.09,0,0,0,1.72-.92L113.76,17a5.21,5.21,0,0,1-3.57,1.55,4.32,4.32,0,0,1-4.47-4.6v-6h-1.53V5.31h1.53V1.4h3.66V5.31h3.14V7.92Z"/>
    <path fill="#1c5bea"
          d="M122.67,5.09A5.43,5.43,0,0,1,126.73,7a6.71,6.71,0,0,1,1.78,4.78,6.82,6.82,0,0,1-1.78,4.82,5.46,5.46,0,0,1-4.16,1.94,4.61,4.61,0,0,1-3.85-1.91V23h-3.63V5.31h3.63V6.76A5.4,5.4,0,0,1,122.67,5.09Zm-4,6.74a3.79,3.79,0,0,0,.88,2.6,2.74,2.74,0,0,0,2.14,1,2.94,2.94,0,0,0,2.21-1,3.61,3.61,0,0,0,.94-2.58,3.86,3.86,0,0,0-.92-2.64,2.79,2.79,0,0,0-2.2-1.07,2.73,2.73,0,0,0-2.17,1A4,4,0,0,0,118.68,11.83Z"/>
    <path fill="#1c5bea" d="M137.48,8.5a2.78,2.78,0,0,0-2.43,1.15,5.26,5.26,0,0,0-.8,3v5.65h-3.63v-13h3.63V7A6,6,0,0,1,136,5.67a4.71,4.71,0,0,1,2.14-.58l0,3.41Z"/>
    <path fill="#1c5bea"
          d="M151.26,16.45a7.27,7.27,0,0,1-5.17,2.08,6.87,6.87,0,0,1-4.91-1.83,6.42,6.42,0,0,1-1.94-4.9,6.35,6.35,0,0,1,2-4.9,6.66,6.66,0,0,1,4.66-1.81,6.76,6.76,0,0,1,4.57,1.62,5.59,5.59,0,0,1,1.88,4.46v1.94h-9.5a2.56,2.56,0,0,0,1.11,1.74,3.59,3.59,0,0,0,2.14.68,4.19,4.19,0,0,0,3.15-1.29Zm-3.49-7.73a2.75,2.75,0,0,0-1.83-.63,3.25,3.25,0,0,0-2,.66,2.45,2.45,0,0,0-1,1.76h5.71A2.58,2.58,0,0,0,147.77,8.72Z"/>
    <path fill="#1c5bea"
          d="M154.64,3.65A2.09,2.09,0,0,1,154,2.13a2.05,2.05,0,0,1,.62-1.51A2.07,2.07,0,0,1,156.15,0a2.09,2.09,0,0,1,1.52.62,2.05,2.05,0,0,1,.62,1.51,2.1,2.1,0,0,1-2.14,2.13A2.06,2.06,0,0,1,154.64,3.65ZM158,18.34h-3.63v-13H158Z"/>
    <path fill="#1c5bea"
          d="M170.62,14.28a3.83,3.83,0,0,1-1.42,3.09,5.52,5.52,0,0,1-3.6,1.16,9.56,9.56,0,0,1-3-.52,8.94,8.94,0,0,1-2.83-1.54l1.6-2.27a6.93,6.93,0,0,0,4.33,1.57,2,2,0,0,0,1.12-.28.85.85,0,0,0,.41-.74,1.05,1.05,0,0,0-.57-.84,6.17,6.17,0,0,0-1.65-.73c-.72-.23-1.26-.42-1.64-.58a8.7,8.7,0,0,1-1.3-.71,3,3,0,0,1-1.48-2.72A3.56,3.56,0,0,1,162,6.23a5.88,5.88,0,0,1,3.75-1.14,8.13,8.13,0,0,1,4.6,1.55L169,9.06a6,6,0,0,0-3.38-1.16c-1.13,0-1.69.3-1.69.92a.89.89,0,0,0,.54.8,11,11,0,0,0,1.76.68c.83.25,1.41.45,1.76.59a9.34,9.34,0,0,1,1.25.64A3,3,0,0,1,170.62,14.28Z"/>
    <path fill="#e64577" d="M171.58,18.13a1.09,1.09,0,0,1,0-1.52,1.07,1.07,0,0,1,1.51,0,1.09,1.09,0,0,1,0,1.52,1.07,1.07,0,0,1-1.51,0Z"/>
    <path fill="#e64577"
          d="M178.06,16.82a2.1,2.1,0,0,0,1.64-.9l1.08,1.21a3.76,3.76,0,0,1-2.73,1.31,3.5,3.5,0,0,1-2.47-.93,3.13,3.13,0,0,1-1-2.42,3.18,3.18,0,0,1,1-2.44,3.49,3.49,0,0,1,2.43-.94,3.87,3.87,0,0,1,1.42.29,3.76,3.76,0,0,1,1.27.86l-1,1.24a2.21,2.21,0,0,0-.77-.58,2.11,2.11,0,0,0-.9-.21,1.77,1.77,0,0,0-1.23.47,1.66,1.66,0,0,0-.52,1.29,1.64,1.64,0,0,0,.52,1.29A1.72,1.72,0,0,0,178.06,16.82Z"/>
    <path fill="#e64577"
          d="M188.4,15.08a3.26,3.26,0,0,1-1,2.39,3.41,3.41,0,0,1-2.47,1,3.37,3.37,0,0,1-2.46-1,3.38,3.38,0,0,1,0-4.78,3.34,3.34,0,0,1,2.46-1,3.38,3.38,0,0,1,2.47,1A3.26,3.26,0,0,1,188.4,15.08Zm-5.07,0a1.88,1.88,0,0,0,.46,1.3,1.6,1.6,0,0,0,2.32,0,1.83,1.83,0,0,0,.46-1.3,1.87,1.87,0,0,0-.46-1.31,1.57,1.57,0,0,0-2.32,0A1.92,1.92,0,0,0,183.33,15.08Z"/>
    <path fill="#e64577"
          d="M195.72,14.83v3.51H193.9V14.78a2.1,2.1,0,0,0-.24-1.14.84.84,0,0,0-.76-.36,1.31,1.31,0,0,0-.94.39,1.54,1.54,0,0,0-.4,1.16v3.51h-1.82V11.82h1.82v.74a2.26,2.26,0,0,1,1.72-.85,1.84,1.84,0,0,1,1.12.37,2.37,2.37,0,0,1,.76.88,2.57,2.57,0,0,1,1-.92,2.73,2.73,0,0,1,1.27-.33,2.54,2.54,0,0,1,1.86.69,2.61,2.61,0,0,1,.7,2v4h-1.83V14.78c0-1-.37-1.5-1.1-1.5a1.33,1.33,0,0,0-.95.39A1.58,1.58,0,0,0,195.72,14.83Z"/>

  </svg>

</template>

<script>
export default {
  name: 'LogoWzftBlack'
}
</script>

