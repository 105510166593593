<template>
  <v-row>
    <v-col cols="12" sm="2" class="pt-0">
      <v-select :items="$store.getters.instance.countries" v-model="currentIso" prepend-icon="mdi-map-marker-outline">
        <template v-slot:item="{parent, item}">
          <div style="width:100%" class="d-flex justify-space-between align-center">
            <div class="mr-4">{{ item }}</div>
            <flags :country="item" style="width:20px; height: 15px" class="pb-5" />
          </div>

        </template>
        <template v-slot:selection="{ item, index }">
          <div class="d-flex justify-space-between align-center">
            <div class="mr-4">{{ item }}</div>
            <flags :country="item" style="width:20px; height: 15px" />
          </div>
        </template>
      </v-select>
    </v-col>
    <v-col cols="12" sm="10" class="pt-0">
      <v-combobox
          ref="locationSearch"
          v-model="current"
          :items="items"
          item-text="cityZip"
          item-value="id"
          :label="lbl"
          :loading="isLoading"
          :search-input.sync="search"
          autocomplete="off"
          no-filter
          @change="change"
          @click="clear"
      ></v-combobox>
    </v-col>
  </v-row>
</template>

<script>

import Flags from '@/_shared/svg/Flags'

export default {
  name: 'LocationSearch',
  components: { Flags,  },
  props: {
    typeOfSearch: {
      type: String
    }
  },
  data () {
    return {
      isLoading: false,
      currentIso: 'DE',
      currentCityZip: '',
      current: {
        iso: 'DE',
        cityZip: ''

      },
      items: [],
      search: ''
    }
  },
  watch: {
    search (val) {
      if (this.isLoading || !val || val.length < 3) {
        return
      }
      this.doSearch(val)
    },
    searchInStore(val) {
      if(!this.$_.isObject(val)) {
        this.search = ''
        this.items = []
        this.$emit('uncomplete', { type: this.typeOfSearch})
      }
    }
  },
  mounted () {
    if(this.$store.getters.search) {
      if(this.$_.isObject(this.$store.getters.search[this.typeOfSearch])) {
        this.current = this.$store.getters.search[this.typeOfSearch]
        this.emitComplete()
      }
    }
  },
  computed: {
    lbl() {
      return (this.$t('main.zip') + ' ' + this.$t('main.and') + '/' + this.$t('main.or') + ' ' + this.$t('main.city')).toUpperCase()
    },
    searchInStore() {
      return this.$store.getters.search ? this.$store.getters.search[this.typeOfSearch] : null
    }
  },
  methods: {
    doSearch (val) {
      if (val.substring(0, 3) === this.currentIso + ' ') {
        return
      }
      this.isLoading = true
      this.$http.api.get('data_geonames', { params: { iso: this.currentIso, cityZip: val } }).then(result => {
        const r = this.$http.filterApiCollection(result, false)
        if (!r) {
          this.isLoading = false
          return
        }
        let cities = []
        this.$_.forEach(r, item => {
          cities.push({ cityZip: item.iso + ' ' + item.zip + ', ' + item.city, city: item.city, zip: item.zip, iso: item.iso, id: item.id })
        })
        this.items = cities
        if (this.items.length === 1) {
          this.current = Object.assign({}, cities[0])
          this.search = this.current.cityZip
          this.emitComplete()
        }
        this.isLoading = false
      })
    },
    change (e) {
      if (this.$_.isObject(e)) {
        this.emitComplete()
      }
    },
    emitComplete () {
      setTimeout(() => { this.$refs['locationSearch'].blur() }, 200)
      this.$store.commit('searchSetLocation', {type: this.typeOfSearch, location:this.current})
      this.$emit('complete', { type: this.typeOfSearch, location: this.current })
    },
    clear () {
      this.search = ''
      this.items = []
      this.$emit('uncomplete', { type: this.typeOfSearch})
    }
  }
}
</script>
