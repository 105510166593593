<template>

  <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 52 40" enable-background="new 0 0 52 40" xml:space="preserve">
<path fill="#CCCCCC" d="M5.2,21.9V18h5.1v-3l5,5l-5,5v-3.1H5.2z"/>
    <path fill="#CCCCCC" d="M46.6,25.7c0.1,0,0.2-0.1,0.2-0.2c0-3.8-0.2-5.1-0.8-5.7c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.1-0.1,0.2,0,0.3
	c0.5,0.5,0.7,1.9,0.7,5.4C46.3,25.6,46.4,25.7,46.6,25.7z"/>
    <path fill="#CCCCCC" d="M45.8,20.1c0.1,0,0.1,0,0.1-0.1c0.1-0.1,0.1-0.2,0-0.3c-0.5-0.5-1.1-2-1.1-4.5c0-0.1-0.1-0.2-0.2-0.2
	c-0.1,0-0.2,0.1-0.2,0.2c0,2.2,0.5,4,1.3,4.8C45.7,20.1,45.8,20.1,45.8,20.1z"/>
    <path fill="#CCCCCC" d="M39,15.5h5.6c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2H39c-0.1,0-0.2,0.1-0.2,0.2
	C38.8,15.4,38.9,15.5,39,15.5z"/>
    <path fill="#CCCCCC" d="M45,25.7h1.5c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2H45c-0.1,0-0.2,0.1-0.2,0.2
	C44.8,25.6,44.9,25.7,45,25.7z"/>
    <path fill="#CCCCCC" d="M45,26.4h2.1c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2H45c-0.1,0-0.2,0.1-0.2,0.2
	C44.8,26.3,44.9,26.4,45,26.4z"/>
    <path fill="#CCCCCC" d="M39,20.1h5.6c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2H39c-0.1,0-0.2,0.1-0.2,0.2
	C38.8,20,38.9,20.1,39,20.1z"/>
    <path fill="#CCCCCC" d="M39,20.1c0.1,0,0.2-0.1,0.2-0.2v-4.6c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2v4.6
	C38.8,20,38.9,20.1,39,20.1z"/>
    <path fill="#CCCCCC" d="M43.7,20.1c0.1,0,0.2-0.1,0.2-0.2v-1.8c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2v1.8
	C43.5,20,43.6,20.1,43.7,20.1z"/>
    <path fill="#CCCCCC" d="M45.4,24.4c0.1,0,0.2-0.1,0.2-0.2v-1.6c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2v1.6
	C45.2,24.3,45.3,24.4,45.4,24.4z"/>
    <path fill="#CCCCCC" d="M44.8,22.2c0.1,0,0.2-0.1,0.2-0.2v-0.3c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2V22
	C44.6,22.2,44.7,22.2,44.8,22.2z"/>
    <path fill="#CCCCCC" d="M38.8,25.7c0-1.5,1.2-2.7,2.7-2.7c1.5,0,2.7,1.2,2.7,2.7c0,1.5-1.2,2.7-2.7,2.7C40,28.4,38.8,27.2,38.8,25.7
	z M39.2,25.7c0,1.3,1,2.3,2.3,2.3c1.3,0,2.3-1,2.3-2.3s-1-2.3-2.3-2.3C40.2,23.4,39.2,24.4,39.2,25.7z"/>
    <path fill="#CCCCCC" d="M39.9,25.7c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6
	C40.6,27.2,39.9,26.5,39.9,25.7z M40.3,25.7c0,0.6,0.5,1.2,1.2,1.2c0.6,0,1.2-0.5,1.2-1.2c0-0.6-0.5-1.2-1.2-1.2
	C40.9,24.5,40.3,25,40.3,25.7z"/>
    <path fill="#CCCCCC" d="M21.2,25.7c0-1.5,1.2-2.7,2.7-2.7c1.5,0,2.7,1.2,2.7,2.7c0,1.5-1.2,2.7-2.7,2.7
	C22.4,28.4,21.2,27.2,21.2,25.7z M21.6,25.7c0,1.3,1,2.3,2.3,2.3s2.3-1,2.3-2.3s-1-2.3-2.3-2.3S21.6,24.4,21.6,25.7z"/>
    <path fill="#CCCCCC" d="M22.4,25.7c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6
	C23.1,27.2,22.4,26.5,22.4,25.7z M22.8,25.7c0,0.6,0.5,1.2,1.2,1.2s1.2-0.5,1.2-1.2c0-0.6-0.5-1.2-1.2-1.2S22.8,25,22.8,25.7z"/>
    <path fill="#CCCCCC" d="M16.7,21.2v-9.4c0-0.1,0.1-0.2,0.2-0.2h20.9c0.1,0,0.2,0.1,0.2,0.2v9.4c0,0.1-0.1,0.2-0.2,0.2H16.9
	C16.7,21.4,16.7,21.3,16.7,21.2z M37.6,12H17.1v9h20.5V12z"/>
    <path fill="#CCCCCC" d="M37.8,25.7c0.1,0,0.2-0.1,0.2-0.2c0.1-0.9,0.5-1.7,1.1-2.2c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0
	c-0.7,0.6-1.1,1.5-1.2,2.5C37.6,25.6,37.6,25.7,37.8,25.7C37.8,25.7,37.8,25.7,37.8,25.7z"/>
    <path fill="#CCCCCC" d="M27.3,24.5h10.8c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2H27.3c-0.1,0-0.2,0.1-0.2,0.2
	C27.1,24.4,27.2,24.5,27.3,24.5z"/>
    <path fill="#CCCCCC" d="M20.2,25.7c0.1,0,0.2-0.1,0.2-0.2c0.1-0.9,0.5-1.7,1.1-2.2c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0
	c-0.7,0.6-1.1,1.5-1.2,2.5C20,25.6,20.1,25.7,20.2,25.7C20.2,25.7,20.2,25.7,20.2,25.7z"/>
    <path fill="#CCCCCC" d="M16.9,24.5h3.7c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2h-3.7c-0.1,0-0.2,0.1-0.2,0.2
	C16.7,24.4,16.7,24.5,16.9,24.5z"/>
</svg>

</template>

<script>
  export default {
    name: 'IconLoadingHinten'
  }
</script>

