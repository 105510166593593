<template>
    <svg version="1.1" id="Ebene_2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 52 40" enable-background="new 0 0 52 40" xml:space="preserve">
<path fill="#CCCCCC" d="M10.4,20.1v-3.9h5.1v-3.1l5,5l-5,5v-3.1H10.4z"/>
        <path fill="#CCCCCC" d="M32.2,27.4L32.2,27.4C32.1,27.4,32.1,27.4,32.2,27.4c-3.3,0-5.4-0.2-7.7-0.4c-0.1,0-0.2-0.1-0.2-0.3
	c0-0.1,0.1-0.2,0.3-0.2c2.3,0.2,4.4,0.4,7.6,0.4c3.3,0,5.3-0.2,7.6-0.4c0.1,0,0.3,0.1,0.3,0.2c0,0.1-0.1,0.3-0.2,0.3
	C37.5,27.3,35.4,27.4,32.2,27.4C32.2,27.4,32.2,27.4,32.2,27.4z"/>
        <path fill="#CCCCCC" d="M26.3,29.8L26.3,29.8c-1.3,0-1.3-0.2-1.3-2.1c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2
	c0,0.8,0,1.4,0.1,1.5c0.1,0.1,0.2,0.1,0.7,0.1h0.1c0.6,0,0.9,0,1-0.1c0.1-0.1,0.1-0.6,0.1-1.2c0-0.1,0.1-0.2,0.2-0.2
	c0.1,0,0.2,0.1,0.2,0.2C28,29.7,27.8,29.8,26.3,29.8z"/>
        <path fill="#CCCCCC" d="M37.9,29.8c-1.5,0-1.6-0.1-1.6-1.8c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.7,0,1.1,0.1,1.2
	c0.1,0.1,0.5,0.1,1,0.1h0c0.5,0,0.6,0,0.7-0.1c0.1-0.2,0.1-0.7,0.1-1.5c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2
	C39.3,29.5,39.2,29.8,37.9,29.8L37.9,29.8z"/>
        <path fill="#CCCCCC" d="M36.9,20.1h-9.6c0,0,0,0,0,0c-0.3,0-0.6-0.2-0.8-0.4c-0.2-0.2-0.3-0.7-0.1-1.4c0-0.1,0.2-0.2,0.3-0.2
	c0.1,0,0.2,0.2,0.2,0.3c-0.1,0.4-0.1,0.7,0.1,0.9c0.1,0.1,0.3,0.2,0.5,0.2h9.5c0.2,0,0.4-0.1,0.5-0.2c0.2-0.2,0.2-0.5,0.1-0.9
	c0-0.1,0-0.3,0.2-0.3c0.1,0,0.3,0,0.3,0.2c0.2,0.7,0,1.1-0.1,1.4C37.6,20,37.3,20.1,36.9,20.1C36.9,20.1,36.9,20.1,36.9,20.1z"/>
        <path fill="#CCCCCC" d="M39.8,10.7H24.5c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2h15.3c0.1,0,0.2,0.1,0.2,0.2
	C40,10.6,39.9,10.7,39.8,10.7z"/>
        <path fill="#CCCCCC" d="M39.8,21.6c-0.1,0-0.2-0.1-0.2-0.2V10.5c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2v10.9
	C40,21.5,39.9,21.6,39.8,21.6z"/>
        <path fill="#CCCCCC" d="M24.5,21.6c-0.1,0-0.2-0.1-0.2-0.2V10.5c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2v10.9
	C24.7,21.5,24.6,21.6,24.5,21.6z"/>
        <path fill="#CCCCCC" d="M23.1,20.4c-0.3,0-0.5-0.2-0.5-0.5v-2.1c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5v2.1
	C23.6,20.2,23.4,20.4,23.1,20.4z"/>
        <path fill="#CCCCCC" d="M41.1,20.4c-0.3,0-0.5-0.2-0.5-0.5v-2.1c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5v2.1
	C41.6,20.2,41.4,20.4,41.1,20.4z"/>
        <path fill="#CCCCCC" d="M34.3,24.3h-4.4c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2h4.4c0.1,0,0.2,0.1,0.2,0.2
	C34.6,24.1,34.5,24.3,34.3,24.3z"/>
        <path fill="#CCCCCC" d="M34.3,23.4h-4.4c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2h4.4c0.1,0,0.2,0.1,0.2,0.2
	C34.6,23.3,34.5,23.4,34.3,23.4z"/>
        <path fill="#CCCCCC" d="M34.3,25.1h-4.4c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2h4.4c0.1,0,0.2,0.1,0.2,0.2
	C34.6,25,34.5,25.1,34.3,25.1z"/>
        <path fill="#CCCCCC" d="M27.7,25.1c-0.6,0-1.2-0.5-1.2-1.2s0.5-1.2,1.2-1.2c0.6,0,1.2,0.5,1.2,1.2S28.3,25.1,27.7,25.1z M27.7,23.3
	c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7S28,23.3,27.7,23.3z"/>
        <path fill="#CCCCCC" d="M26.8,22.6c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4c0.2,0,0.4,0.2,0.4,0.4S27,22.6,26.8,22.6z M26.8,22.1
	C26.7,22.1,26.7,22.2,26.8,22.1C26.7,22.3,26.8,22.3,26.8,22.1C26.8,22.2,26.8,22.1,26.8,22.1z"/>
        <path fill="#CCCCCC" d="M36.6,25.1c-0.6,0-1.2-0.5-1.2-1.2s0.5-1.2,1.2-1.2c0.6,0,1.2,0.5,1.2,1.2S37.2,25.1,36.6,25.1z M36.6,23.3
	c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7S36.9,23.3,36.6,23.3z"/>
        <path fill="#CCCCCC" d="M37.5,22.6c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4c0.2,0,0.4,0.2,0.4,0.4S37.7,22.6,37.5,22.6z M37.5,22.1
	C37.5,22.1,37.4,22.2,37.5,22.1C37.4,22.3,37.5,22.3,37.5,22.1C37.5,22.2,37.5,22.1,37.5,22.1z"/>
        <path fill="#CCCCCC" d="M32.1,26.7C32.1,26.7,32.1,26.7,32.1,26.7L32.1,26.7C32,26.7,32,26.7,32.1,26.7c-3,0-5-0.1-7.1-0.4
	c-0.1,0-0.2-0.1-0.2-0.2c0-1.9,0-4.5,0.6-5.8c0.1-0.2,0.2-0.4,0.3-0.7c0.2-0.3,0.4-0.7,0.6-1.1l1.2-4.1c0-0.1,0.1-0.2,0.2-0.2h8.7
	c0.1,0,0.2,0.1,0.2,0.2l1.2,4.1c0.2,0.4,0.4,0.7,0.5,1.1c0.1,0.2,0.3,0.5,0.3,0.7c0.6,1.3,0.6,3.9,0.6,5.8c0,0.1-0.1,0.2-0.2,0.2
	C37.1,26.5,35.2,26.7,32.1,26.7C32.1,26.7,32.1,26.7,32.1,26.7z M25.3,25.8c2,0.2,3.9,0.3,6.7,0.3c2.9,0,4.9-0.1,6.8-0.3
	c0-1.8,0-4.2-0.5-5.4c-0.1-0.2-0.2-0.4-0.3-0.6c-0.2-0.3-0.4-0.7-0.6-1.1l-1.2-3.9H28l-1.2,3.9c-0.2,0.5-0.4,0.9-0.6,1.2
	c-0.1,0.2-0.3,0.4-0.3,0.6C25.4,21.6,25.3,24,25.3,25.8z"/>
</svg>

</template>

<script>
  export default {
    name: 'IconLoadingSeitlich'
  }
</script>

