<template>
  <div>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field v-model="freight.weight" type="text" :suffix="$tc('main.kilogram', 2)" :label="$t('main.loading_weight')"  :placeholder="$t('main.i_e') + ' 4500'" @click="freight.weight = ''"
                      @change="onChange('weight')"/>
      </v-col>

      <v-col cols="12" md="6" id="ntx-text-length">
        <v-text-field v-model="freight.length" type="text" :suffix="$tc('main.meter', 2)" :label="$t('main.loading_length')" :placeholder="$t('main.i_e') + ' 3,5'" @click="freight.length = ''"
                      @change="onChange('length')"/>
      </v-col>


      <v-col cols="12" md="6">
        <v-text-field v-model="freight.width" type="text" :suffix="$tc('main.meter', 2)" :label="$t('main.loading_width')" :placeholder="$t('main.i_e') + ' 1,8'" @click="freight.width = ''"
                      @change="onChange('width')" @blur="onBlur"/>
      </v-col>


      <v-col cols="12" md="6">
        <v-text-field v-model="freight.height" type="text" :suffix="$tc('main.meter', 2)" :label="$t('main.loading_height')" :placeholder="$t('main.i_e') + ' 1,8'" @click="freight.height = ''"
                      @change="onChange('height')" @blur="onBlur"/>
      </v-col>




    </v-row>


    <!-- MODALS -->



    <v-dialog width="300" v-model="dialog.alertHeight">
      <v-card>
        <v-card-title class="headline">
          {{ $t('main.note') }}
        </v-card-title>

        <v-card-text>
          <span>{{$t('main.freight_warning_height')}}</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialog.alertHeight = false"> OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog width="300" v-model="dialog.alertLength">
      <v-card>
        <v-card-title class="headline">
          {{ $t('main.note') }}
        </v-card-title>

        <v-card-text>
          <span>{{$t('main.freight_warning_length')}}</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialog.alertLength = false"> OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog width="300" v-model="dialog.alertWidth">
      <v-card>
        <v-card-title class="headline">
          {{ $t('main.note') }}
        </v-card-title>

        <v-card-text>
          <span>{{$t('main.freight_warning_width')}}</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialog.alertWidth = false"> OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- //ALERT MODALS -->

  </div>
</template>

<script>
export default {
  name: 'Freight',
  data () {
    return {
      freight: {
        weight: 0,
        height: 0,
        width: 0,
        length: 0
      },
      dialog: {
        weight: false,
        height: false,
        width: false,
        length: false,
        alertHeight: false,
        alertLength: false,
        alertWidth: false,
        alertHeightDone: false,
        alertLengthDone: false,
        alertWidthDone: false
      },
      inTransit: false
    }
  },
  computed: {
    isComplete () {
      if (this.$utils.format.germanFloatToFloat(this.freight.length) > 0
          && this.$utils.format.germanFloatToFloat(this.freight.height) > 0
          && this.$utils.format.germanFloatToFloat(this.freight.width) > 0
          && this.$utils.format.germanFloatToFloat(this.freight.weight) > 0 ) {
        return true
      }
      return false
    }
  },
  watch: {
    'freight.length': {
      handler: function (n) {
        if (parseInt(n) > 20) {
          if (!this.dialog.alertLengthDone) {
            this.dialog.alertLength = true
            this.dialog.alertLengthDone = true
          }
        }
        if(this.isComplete) {
          this.emitComplete()
        } else {
          this.emitUncomplete()
        }
      }
    },
    'freight.height': {
      handler: function (n) {
        if (parseInt(n) > 5) {
          if (!this.dialog.alertHeightDone) {
            this.dialog.alertHeight = true
            this.dialog.alertHeightDone = true
          }
        }
        if(this.isComplete) {
          this.emitComplete()
        } else {
          this.emitUncomplete()
        }
      }
    },
    'freight.width': {
      handler: function (n) {
        if (parseInt(n) > 3) {
          if (!this.dialog.alertWidthDone) {
            this.dialog.alertWidth = true
            this.dialog.alertWidthtDone = true
          }
        }
        if(this.isComplete) {
          this.emitComplete()
        } else {
          this.emitUncomplete()
        }
      }
    },
    'freight.weight': {
      handler: function() {
        if(this.isComplete) {
          this.emitComplete()
        } else {
          this.emitUncomplete()
        }
      }
    }
  },
  mounted () {
    if(this.$store.getters.search.freight) {
      const freight = this.$store.getters.search.freight
      this.freight = {
        height:this.$utils.format.toGermanFloat(freight.height),
        width: this.$utils.format.toGermanFloat(freight.width),
        length:this.$utils.format.toGermanFloat(freight.length),
        weight:this.$utils.format.toGermanFloat(freight.weight)
      }
    }
  },
  methods: {
    onChange (e) {
      this.freight[e] = this.$utils.format.toGermanFloat(this.freight[e], e === 'weight' ? 0 : 2)
    },
    onBlur() {
      this.$emit('blur')
    },
    emitComplete () {
      const freight = {
        length: this.$utils.format.germanFloatToFloat(this.freight.length),
        height: this.$utils.format.germanFloatToFloat(this.freight.height),
        width: this.$utils.format.germanFloatToFloat(this.freight.width),
        weight: this.$utils.format.germanFloatToFloat(this.freight.weight)
      }
      this.$store.commit('searchSetFreight', freight)
      this.$emit('complete', { freight: freight })
    },
    emitUncomplete () {
      this.$emit('uncomplete')
    }
  }
}
</script>
