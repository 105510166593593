<template>
  <v-card :loading="isLoading">
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="12" md="4">
              <AddressInput type="billing" @complete="addressComplete" @uncomplete="addressUncomplete" @change="onChange"></AddressInput>
            </v-col>
            <v-col cols="12" md="4">
              <AddressInput type="sender"  :billing-address="billingAddress" @complete="addressComplete" @uncomplete="addressUncomplete" @change="onChange"></AddressInput>
            </v-col>
            <v-col cols="12" md="4">
              <AddressInput type="receiver"  :billing-address="billingAddress" @complete="addressComplete" @uncomplete="addressUncomplete" @change="onChange"></AddressInput>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>

import AddressInput from '@/anfrage/components/inquiry/AddressInput'

export default {
  name: 'InquiryAddresses',
  components: { AddressInput },
  props: {
    step: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      isLoading: false,
      addresses: {
        billing: null
      },
      countries: [],
      complete: {
        billing: false,
        sender: false,
        receiver: false
      }
    }
  },
  watch: {
    isValid (n) {
      if (n) {
        this.$emit('complete', this.step)
        this.saveToStore()
      } else {
        this.$emit('uncomplete', this.step)
      }
    }
  },
  computed: {
    billingAddress () {
      return this.complete.billing ? this.addresses.billing : null
    },
    isValid () {
      return this.complete.billing
    }

  },
  mounted () {
    this.init()
  },

  methods: {
    onChange (type, address) {
      this.addresses[type] = address
      this.saveToStore()

    },
    addressComplete (type) {
      this.complete[type] = true

    },
    addressUncomplete (type) {
      this.complete[type] = false
    },
    init () {
      this.$emit(this.isValid ? 'complete' : 'uncomplete', 1)
    },
    saveToStore () {
      this.$store.commit('setAddress', { data: { billing: this.addresses.billing, sender: this.addresses.sender, receiver: this.addresses.receiver } })
    }
  }
}
</script>

