<template>
    <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 52 40" enable-background="new 0 0 52 40" xml:space="preserve">
<path fill="#CCCCCC" d="M24.1,6H28v5.1h3l-5,5l-5-5h3.1V6z"/>
        <path fill="#CCCCCC" d="M40.6,32.7c0.1,0,0.2-0.1,0.2-0.2c0-3.8-0.2-5.1-0.8-5.7c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.1-0.1,0.2,0,0.3
	c0.5,0.5,0.7,1.9,0.7,5.4C40.3,32.6,40.4,32.7,40.6,32.7z"/>
        <path fill="#CCCCCC" d="M39.8,27.1c0.1,0,0.1,0,0.1-0.1c0.1-0.1,0.1-0.2,0-0.3c-0.5-0.5-1.1-2-1.1-4.5c0-0.1-0.1-0.2-0.2-0.2
	c-0.1,0-0.2,0.1-0.2,0.2c0,2.2,0.5,4,1.3,4.8C39.7,27.1,39.8,27.1,39.8,27.1z"/>
        <path fill="#CCCCCC" d="M33,22.5h5.6c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2H33c-0.1,0-0.2,0.1-0.2,0.2
	C32.8,22.4,32.9,22.5,33,22.5z"/>
        <path fill="#CCCCCC" d="M39,32.7h1.5c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2H39c-0.1,0-0.2,0.1-0.2,0.2
	C38.8,32.6,38.9,32.7,39,32.7z"/>
        <path fill="#CCCCCC" d="M39,33.4h2.1c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2H39c-0.1,0-0.2,0.1-0.2,0.2
	C38.8,33.3,38.9,33.4,39,33.4z"/>
        <path fill="#CCCCCC" d="M33,27.1h5.6c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2H33c-0.1,0-0.2,0.1-0.2,0.2
	C32.8,27,32.9,27.1,33,27.1z"/>
        <path fill="#CCCCCC" d="M33,27.1c0.1,0,0.2-0.1,0.2-0.2v-4.6c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2v4.6
	C32.8,27,32.9,27.1,33,27.1z"/>
        <path fill="#CCCCCC" d="M37.7,27.1c0.1,0,0.2-0.1,0.2-0.2v-1.8c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2v1.8
	C37.5,27,37.6,27.1,37.7,27.1z"/>
        <path fill="#CCCCCC" d="M39.4,31.4c0.1,0,0.2-0.1,0.2-0.2v-1.6c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2v1.6
	C39.2,31.3,39.3,31.4,39.4,31.4z"/>
        <path fill="#CCCCCC" d="M38.8,29.2c0.1,0,0.2-0.1,0.2-0.2v-0.3c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2V29
	C38.6,29.2,38.7,29.2,38.8,29.2z"/>
        <path fill="#CCCCCC" d="M32.8,32.7c0-1.5,1.2-2.7,2.7-2.7c1.5,0,2.7,1.2,2.7,2.7c0,1.5-1.2,2.7-2.7,2.7C34,35.4,32.8,34.2,32.8,32.7
	z M33.2,32.7c0,1.3,1,2.3,2.3,2.3c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3C34.2,30.4,33.2,31.4,33.2,32.7z"/>
        <path fill="#CCCCCC" d="M33.9,32.7c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6
	C34.6,34.2,33.9,33.5,33.9,32.7z M34.3,32.7c0,0.6,0.5,1.2,1.2,1.2c0.6,0,1.2-0.5,1.2-1.2c0-0.6-0.5-1.2-1.2-1.2
	C34.9,31.5,34.3,32,34.3,32.7z"/>
        <path fill="#CCCCCC" d="M15.2,32.7c0-1.5,1.2-2.7,2.7-2.7c1.5,0,2.7,1.2,2.7,2.7c0,1.5-1.2,2.7-2.7,2.7
	C16.4,35.4,15.2,34.2,15.2,32.7z M15.6,32.7c0,1.3,1,2.3,2.3,2.3s2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3S15.6,31.4,15.6,32.7z"/>
        <path fill="#CCCCCC" d="M16.4,32.7c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6
	C17.1,34.2,16.4,33.5,16.4,32.7z M16.8,32.7c0,0.6,0.5,1.2,1.2,1.2s1.2-0.5,1.2-1.2c0-0.6-0.5-1.2-1.2-1.2S16.8,32,16.8,32.7z"/>
        <path fill="#CCCCCC" d="M10.7,28.2v-9.4c0-0.1,0.1-0.2,0.2-0.2h20.9c0.1,0,0.2,0.1,0.2,0.2v9.4c0,0.1-0.1,0.2-0.2,0.2H10.9
	C10.7,28.4,10.7,28.3,10.7,28.2z M31.6,19H11.1v9h20.5V19z"/>
        <path fill="#CCCCCC" d="M31.8,32.7c0.1,0,0.2-0.1,0.2-0.2c0.1-0.9,0.5-1.7,1.1-2.2c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0
	c-0.7,0.6-1.1,1.5-1.2,2.5C31.6,32.6,31.6,32.7,31.8,32.7C31.8,32.7,31.8,32.7,31.8,32.7z"/>
        <path fill="#CCCCCC" d="M21.3,31.5h10.8c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2H21.3c-0.1,0-0.2,0.1-0.2,0.2
	C21.1,31.4,21.2,31.5,21.3,31.5z"/>
        <path fill="#CCCCCC" d="M14.2,32.7c0.1,0,0.2-0.1,0.2-0.2c0.1-0.9,0.5-1.7,1.1-2.2c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0
	c-0.7,0.6-1.1,1.5-1.2,2.5C14,32.6,14.1,32.7,14.2,32.7C14.2,32.7,14.2,32.7,14.2,32.7z"/>
        <path fill="#CCCCCC" d="M10.9,31.5h3.7c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2h-3.7c-0.1,0-0.2,0.1-0.2,0.2
	C10.7,31.4,10.7,31.5,10.9,31.5z"/>
</svg>

</template>

<script>
  export default {
    name: 'IconLoadingOben'
  }
</script>

