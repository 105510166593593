<template>
  <div id="map_wrapper" class="ma-0">
    <div id="map"/>
  </div>
</template>

<script>
export default {
  name: 'GMapRoute',
  data () {
    return {
      pMap: null,
      map: null,
      directionsService: null,
      directionsDisplay: null,
      current: {},
      interval: 0
    }
  },
  mounted () {
    if (!this.interval) {
      this.interval = setInterval(() => {
        if (this.$root.googleMapsIsLoaded) {
          clearInterval(this.interval)
          this.initMap()
        }
      }, 1000)
    }
  }
  ,
  methods: {
    setRoute (c) {
      this.current = c
      this.calculateAndDisplayRoute(this.directionsService, this.directionsDisplay)
    },
    initMap () {
      // eslint-disable-next-line
      this.directionsService = new google.maps.DirectionsService
      // eslint-disable-next-line
      this.directionsDisplay = new google.maps.DirectionsRenderer
      // eslint-disable-next-line
      this.map = new google.maps.Map(document.getElementById('map'), {
        zoom: 8,
        center: { lat: 51.661610, lng: 10.569117 }
      })
      this.directionsDisplay.setMap(this.map)
      //this.$events.fire('map-init')
      this.$emit('map-init')
    },
    calculateAndDisplayRoute (ds, dp) {
      try {
      ds.route({
        origin: this.current.from.zip + ' ' + this.current.from.iso,
        destination: this.current.to.zip + ' ' + this.current.to.iso,
        travelMode: 'DRIVING'
      }, (response, status) => {
        if (status === 'OK') {
          //this.$events.fire('map-build')
          this.$emit('map-build')
          dp.setDirections(response)
          setTimeout(() => {
            this.map.setZoom(6)
          }, 1000)
        } else {
          this.$emit('map-route-not-found')
        }
      }) } catch(err) {
        this.$emit('map-route-not-found')
      }
    }
  }
}
</script>
