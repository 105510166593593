<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" class="">
          <v-stepper alt-labels v-model="step" class="elevation-0">
            <v-stepper-header>
              <template v-for="(n, i) in computedSteps">
                <v-stepper-step :key="`${i + 1}-step`" :complete="n.completed" :step="i + 1">
                  <span v-if="$vuetify.breakpoint.smAndUp" class="subtitle-2" v-text="$_.upperFirst(n.title)"/>
                </v-stepper-step>
                <v-divider v-if="i < computedSteps.length - 1" :key="`${i + 1}-div`"></v-divider>
              </template>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <InquiryLoadingTimes ref="inquiryLoadingTimes" @complete="onComplete" @uncomplete="onUncomplete" :step="1"/>
                <v-row class="mt-6">
                  <v-col cols="12" class="d-flex justify-space-between">
                    <v-btn v-ripple v-blur @click="$router.back()">
                      <v-icon left>mdi-chevron-left</v-icon>
                      {{ $t('main.back') }}
                    </v-btn>
                    <v-btn v-ripple v-blur @click="nextStep">{{ $t('main.next') }}
                      <v-icon right>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-stepper-content>

              <v-stepper-content step="2">
                <InquiryAddons ref="inquiryAddons" @complete="onComplete" @uncomplete="onUncomplete" :step="2"/>
                <v-row class="mt-6">
                  <v-col cols="12" class="d-flex justify-space-between">
                    <v-btn v-ripple v-blur @click="prevStep">
                      <v-icon left>mdi-chevron-left</v-icon>
                      {{ $t('main.back') }}
                    </v-btn>
                    <v-btn v-ripple v-blur @click="nextStep">{{ $t('main.next') }}
                      <v-icon right>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-stepper-content>

              <v-stepper-content step="3">
                <InquiryAddresses v-if="step===3" ref="inquiryAddresses" @complete="onComplete" @uncomplete="onUncomplete" :step="3"/>
                <v-row class="mt-6">
                  <v-col cols="12" class="d-flex justify-space-between">
                    <v-btn v-ripple v-blur @click="prevStep">
                      <v-icon left>mdi-chevron-left</v-icon>
                      {{ $t('main.back') }}
                    </v-btn>
                    <v-btn :disabled="!this.steps[2].completed" v-ripple v-blur @click="nextStep">{{ $t('main.next') }}
                      <v-icon right>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-stepper-content>

              <v-stepper-content step="4">
                <InquiryNotice v-if="step===4" ref="inquiryNotice" @complete="onComplete" @uncomplete="onUncomplete" :step="4"/>
                <v-row class="mt-6">
                  <v-col cols="12" class="d-flex justify-space-between">
                    <v-btn v-ripple v-blur @click="prevStep">
                      <v-icon left>mdi-chevron-left</v-icon>
                      {{ $t('main.back') }}
                    </v-btn>
                    <v-btn :disabled="!this.steps[3].completed" v-ripple v-blur @click="nextStep">{{ $t('main.next') }}
                      <v-icon right>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-stepper-content>

              <v-stepper-content step="5">
                <InquiryInsurance v-if="step===5" ref="inquiryInsurance" :currency="$store.getters.instance.currency" :step="5"/>
                <v-row class="mt-6">
                  <v-col cols="12" class="d-flex justify-space-between">
                    <v-btn v-ripple v-blur @click="prevStep">
                      <v-icon left>mdi-chevron-left</v-icon>
                      {{ $t('main.back') }}
                    </v-btn>
                    <v-btn v-ripple v-blur @click="nextStep">{{ $t('main.next') }}
                      <v-icon right>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-stepper-content>

              <v-stepper-content step="6">
                <InquiryOverview v-if="step===6" ref="inquiryOverview" :step="6"></InquiryOverview>
                <v-row class="mt-6" v-if="!isFinished && !isLoading">
                  <v-col cols="12" class="d-flex justify-space-between">
                    <v-btn v-ripple v-blur @click="prevStep">
                      <v-icon left>mdi-chevron-left</v-icon>
                      {{ $t('main.back') }}
                    </v-btn>
                    <v-btn v-ripple color="primary" v-blur @click="send">{{ $t('main.calculator_send_query') }}
                      <v-icon right>mdi-send</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row class="mt-6" v-if="isFinished && !isLoading">
                  <v-col cols="12" class="d-flex justify-space-between">
                    <v-btn v-ripple v-blur @click="back()">
                      <v-icon left>mdi-chevron-left</v-icon>
                      {{ $t('main.calculator_new_query') }}
                    </v-btn>
                  </v-col>
                </v-row>

              </v-stepper-content>


            </v-stepper-items>
          </v-stepper>
        </v-col>
      </v-row>

    </v-container>

    <v-dialog
        v-model="dialogFinish"
        persistent
        width="500"
    >
      <v-card>
        <v-card-title class="headline" primary-title>
          {{ headlineDialogFinish }}
        </v-card-title>
        <v-card-text>
          <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
              v-if="isLoading"
          ></v-progress-linear>
        </v-card-text>
        <v-card-actions v-if="isFinished">
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialogFinish = false">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import InquiryAddons from '@/anfrage/components/inquiry/InquiryAddons'
import InquiryAddresses from '@/anfrage/components/inquiry/InquiryAddresses'
import InquiryInsurance from '@/anfrage/components/inquiry/InquiryInsurance'
import InquiryLoadingTimes from '@/anfrage/components/inquiry/InquiryLoadingTimes'
import InquiryNotice from '@/anfrage/components/inquiry/InquiryNotice'
import InquiryOverview from '@/anfrage/components/inquiry/InquiryOverview'

export default {
  name: 'Inquiry',
  components: { InquiryOverview, InquiryInsurance, InquiryNotice, InquiryAddresses, InquiryAddons, InquiryLoadingTimes },
  data () {
    return {
      step: 1,
      isLoading: false,
      isFinished: false,
      dialogFinish: false,
      steps: [
        { completed: false },
        { completed: false },
        { completed: false },
        { completed: false },
        { completed: false },
        { completed: false }
      ]
    }
  },
  computed: {
    headlineDialogFinish () {
      return this.isFinished ? this.$t('prompts.send_success') : this.$t('prompts.submit_data')
    },
    lastStep () {
      return this.computedSteps.length
    },
    computedSteps () {
      return [
        { title: this.$tc('main.time', 2), completed: false, ref: 'inquiryLoadingTimes' },
        { title: this.$tc('main.service', 2), completed: false, ref: 'inquiryAddons' },
        { title: this.$tc('main.address', 2), completed: false, ref: 'inquiryAddresses' },
        { title: this.$t('main.cargo'), completed: false, ref: 'inquiryNotice' },
        { title: this.$t('main.insurance'), completed: false, ref: 'inquiryInsurance' },
        { title: this.$t('main.confirmation'), completed: false, ref: 'inquiryOverview' }]
    }
  },
  mounted () {
    this.$utils.scrollToTop()
    this.search = this.$store.getters.search

  },
  methods: {
    onComplete (step) {
      this.steps[step - 1].completed = true
    },
    onUncomplete (step) {
      this.steps[step - 1].completed = false
    },
    nextStep () {
      try {
        this.$refs[this.computedSteps[this.step].ref].init()
      } catch (e) {
        //
      }
      try {
        this.$refs[this.computedSteps[this.step - 1].ref].saveToStore()
      } catch (e) {
        //
      }
      this.steps[this.step - 1].completed = true
      this.step++

    },
    prevStep () {
      if (this.step === 1) {
        this.$router.back()
        return
      }
      this.step--
    },
    send () {
      this.isLoading = true
      this.$http.etc.post('save_order', {
        id_instance: this.$store.getters.instance.id,
        search: this.$store.getters.search,
        inquiry: this.$store.getters.inquiry,
        lang: this.$root.$i18n.locale
      })
      .then(() => {
        this.dialogFinish = true
        this.isLoading = false
        this.isFinished = true
        this.computedSteps[this.computedSteps.length - 1].completed = true
      })
      .catch(() => {
        this.isLoading = false
      })
    },
    back () {
      this.$store.dispatch('clearSessionData')
      this.$router.push('/')
    }
  }
}
</script>
