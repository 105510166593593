<template>
  <div>
    <svg v-if="country=='DE'" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 40 30" enable-background="new 0 0 40 30" xml:space="preserve">
      <rect x="0" y="0" style="fill:#434A54;" width="40" height="10"/>
      <rect x="0" y="10" style="fill:#ED5565;" width="40" height="10"/>
      <rect x="0" y="20" style="fill:#F6BB42;" width="40" height="10"/>
    </svg>

    <svg v-if="country=='NL'" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 40 30" enable-background="new 0 0 40 30" xml:space="preserve">
      <rect x="0" y="0" style="fill:#ed5565;" width="40" height="10"/>
      <rect x="0" y="10" style="fill:#ffffff;" width="40" height="10"/>
      <rect x="0" y="20" style="fill:#4758a9;" width="40" height="10"/>
    </svg>

    <svg v-if="country=='FR'" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 40 30" enable-background="new 0 0 40 30" xml:space="preserve">
      <rect x="0" y="0" style="fill:#4758a9;" width="13" height="30"/>
      <rect x="13" y="0" style="fill:#ffffff;" width="13" height="30"/>
      <rect x="26" y="0" style="fill:#ed5565;" width="14" height="30"/>
    </svg>

    <svg v-if="country=='DK'" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 40 30" enable-background="new 0 0 40 30" xml:space="preserve">
      <rect x="0" y="0" style="fill:#ed5565;" width="40" height="30"/>
      <rect x="0" y="13" style="fill:#ffffff;" width="40" height="4"/>
      <rect x="13" y="0" style="fill:#ffffff;" width="4" height="30"/>
    </svg>

    <svg v-if="country=='ES'" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 40 30" enable-background="new 0 0 40 30" xml:space="preserve">
      <rect x="0" y="0" style="fill:#ed5565;" width="40" height="7"/>
      <rect x="0" y="7" style="fill:#ffce54;" width="40" height="16"/>
      <rect x="0" y="23" style="fill:#ed5565;" width="40" height="7"/>
    </svg>

    <svg v-if="country=='CH'" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 40 30" enable-background="new 0 0 40 30" xml:space="preserve">
      <rect x="0" y="0" style="fill:#ed5565;" width="40" height="30"/>
      <rect x="18" y="6" style="fill:#ffffff;" width="4" height="18"/>
      <rect x="11" y="13" style="fill:#ffffff;" width="18" height="4"/>
    </svg>

    <svg v-if="country=='IT'" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 40 30" enable-background="new 0 0 40 30" xml:space="preserve">
      <rect x="0" y="0" style="fill:#57a863;" width="13" height="30"/>
      <rect x="13" y="0" style="fill:#ffffff;" width="13" height="30"/>
      <rect x="26" y="0" style="fill:#ed5565;" width="14" height="30"/>
    </svg>

    <svg v-if="country=='LU'" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 40 30" enable-background="new 0 0 40 30" xml:space="preserve">
      <rect x="0" y="0" style="fill:#ed5565;" width="40" height="10"/>
      <rect x="0" y="10" style="fill:#ffffff;" width="40" height="10"/>
      <rect x="0" y="20" style="fill:#3bafda;" width="40" height="10"/>
    </svg>

    <svg v-if="country=='PL'" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 40 30" enable-background="new 0 0 40 30" xml:space="preserve">
      <rect x="0" y="0" style="fill:#ffffff;" width="40" height="15"/>
      <rect x="0" y="15" style="fill:#ed5565;" width="40" height="15"/>
    </svg>

    <svg v-if="country=='AT'" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 40 30" enable-background="new 0 0 40 30" xml:space="preserve">
      <rect x="0" y="0" style="fill:#ed5565;" width="40" height="10"/>
      <rect x="0" y="10" style="fill:#ffffff;" width="40" height="10"/>
      <rect x="0" y="20" style="fill:#ed5565;" width="40" height="10"/>
    </svg>

    <svg v-if="country=='BE'" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 40 30" enable-background="new 0 0 40 30" xml:space="preserve">
      <rect x="0" y="0" style="fill:#434A54;" width="13" height="30"/>
      <rect x="13" y="0" style="fill:#F6BB42;" width="13" height="30"/>
      <rect x="26" y="0" style="fill:#ED5565;" width="14" height="30"/>
    </svg>

    <svg v-if="country=='CZ'" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 40 30" enable-background="new 0 0 40 30" xml:space="preserve">
      <rect x="0" y="0" style="fill:#ffffff;" width="40" height="15"/>
      <rect x="0" y="15" style="fill:#ed5565;" width="40" height="15"/>
      <polygon points="0,0 15,15 0,30"  style="fill:#4758a9;"/>
    </svg>

  </div>
</template>

<script>
export default {
  name: 'Flags',
  props: {
    country: {
      type: String,
      default: 'DE'
    }
  }
}
</script>
