<template>
  <div className="d-flex align-center justify-center ">
    <img v-if="!hasLink || !link" :src="imageSrc" style="max-height:40px;max-width:200px"/>
    <img v-else :src="imageSrc" style="max-height:40px;max-width:200px;cursor:pointer" @click="openLink"/>
  </div>
</template>

<script>
export default {
  name: 'LogoImage',
  props: {
    isInstanceLogo: {
      type: Boolean,
      default: true
    },
    idInstance: {
      type: Number,
      default: 0
    },
    hasLink: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      imageSrc: ''
    }
  },
  computed: {
    link () {
      if (!this.$store.getters.instance.website) {
        return null
      } else {
        let l = this.$store.getters.instance.website
        if (l.substr(0, 4) != 'http') {
          l = 'http://' + l
        }
        return l
      }

    }
  },
  mounted () {
    this.getImage()
  },
  methods: {
    getImage () {
      let params = this.isInstanceLogo ? { type: 'website' } : { type: 'email' }
      if (this.idInstance) {
        params.id_instance = parseInt(this.idInstance)
      }
      this.$http.etc.get('get_logo', { params: params })
      .then(r => {
        this.imageSrc = r.data
      })
      .catch(() => {
        this.doNoticeWarning('Keine Logodatei gefunden.')
      })
    },
    openLink () {
      window.open(this.link, '_blank');
    }
  },

}
</script>


