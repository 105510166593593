<template>
  <div>
    <!-- INPUT -->
    <v-container style="max-width: 1000px" grid-list-md fluid fill-height align-center justify-center>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title class="primary">
              <v-row>
                <v-col cols="12" class="py-0"><span style="display: inline-block;max-width:80vw" class="caption grey--text text--lighten-3 text-truncate">{{ routeText }}</span></v-col>
                <v-col cols="12" class="py-0"><span class="headline white--text">{{ $t('main.calculator_title') }}</span></v-col>
              </v-row>
            </v-card-title>

            <!-- INPUT DATA -->
            <v-card-text v-show="card==0">
              <v-row>
                <v-col cols="12">
                  <v-stepper v-model="step" vertical class="elevation-0" non-linear>

                    <v-stepper-step step="1" :complete="locationsSet" @click="step=1">
                      {{$t('main.loading_and_unloading_point').toUpperCase()}}
                    </v-stepper-step>
                    <v-stepper-content step="1">
                      <Location @complete="locationsAreSet" v-if="!locationIsReloading" @uncomplete="locationsAreUnset" @blur="locationsBlur">></Location>
                    </v-stepper-content>

                    <v-stepper-step step="2" :complete="freightSet" @click="step=2">
                      {{$t('main.freight_description').toUpperCase()}}
                    </v-stepper-step>
                    <v-stepper-content step="2">
                      <Freight @complete="freightIsSet" @uncomplete="freightIsUnset" @blur="freightBlur"></Freight>
                    </v-stepper-content>

                    <v-stepper-step step="3" :complete="loadingSet" @click="step=3">
                      {{$t('main.loading').toUpperCase()}}
                    </v-stepper-step>
                    <v-stepper-content step="3">
                      <v-row>
                        <v-col cols="12">
                          <Loading @complete="loadingIsSet" @uncomplete="loadingIsUnset"></Loading>
                        </v-col>
                      </v-row>
                    </v-stepper-content>

                  </v-stepper>
                </v-col>
              </v-row>
            </v-card-text>
            <!-- //INPUT -->

            <!-- GMAP -->
            <v-card-text style="max-width: 100%;overflow: hidden" v-show="card==1" class="pa-0">
              <g-map-route ref="gmap" @map-route-not-found="onMapRouteNotFound"/>
            </v-card-text>
            <!-- //GMAP -->

            <v-card-actions>
              <v-row class="px-2" v-if="card==0" dense>
                <v-col cols="4" sm="3">
                  <v-btn v-blur ripple block :disabled="step<2" @click="prevStep()">
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="4" sm="3">
                  <v-btn v-blur block :disabled="step>2" @click="nextStep()">
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-btn v-blur block :disabled="!locationsSet || card==1 || !computedRouteFound" @click="openMap()">
                    <v-icon left>mdi-map</v-icon>
                    <span>{{$t('main.map')}}</span>
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-btn v-blur block color="primary" :disabled="!allSet" @click="openInquiry()">
                    <span>{{$t('main.next')}}</span>
                    <v-icon class="ml-2">mdi-chevron-right</v-icon>

                  </v-btn>
                </v-col>
              </v-row>

              <v-row v-if="card==1" dense>
                <v-col cols="12">
                  <v-btn v-blur block @click="prevStep()">
                    <v-icon left> mdi-close</v-icon>
                    <span> {{$t('main.map')}} {{$t('main.close')}}</span>
                  </v-btn>
                </v-col>
              </v-row>


            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>


  </div>
</template>

<script>
import Freight from '@/anfrage/components/input/Freight'
import Loading from '@/anfrage/components/input/Loading'
import Location from '@/anfrage/components/input/Location'
import GMapRoute from '../components/GMapRoute'

export default {
  name: 'Input',
  components: { Loading, Freight, Location, GMapRoute },
  data () {
    return {
      isLoading: false,
      cardIsLoading: false,
      searchIsLoading: false,
      locationIsReloading: false,
      card: 0,
      isCard: true,
      step: 1,
      locationsSet: false,
      zoneSet: false,
      freightSet: false,
      loadingSet: false,
      current: {
        from: {},
        to: {},
        mapDistance: '',
        freight: {
          weight: 0,
          length: 0,
          height: 0
        },
        loading: 0,
        unloading: 0
      },
      mapDistance: '',
      debouncedSearch: null,
      routeFound: true
    }
  },
  watch: {},
  computed: {

    routeText () {
      return !this.locationsSet ? '' : ((this.current.mapDistance ? Math.round(this.current.mapDistance) + ' ' + this.$t('main.km') + ' ' + this.$t('main.from') : this.$t('main.from')) + ' '  + this.current.from.cityZip + ' ' + this.$t('main.to') + ' ' + this.current.to.cityZip)
    },
    allSet () {
      return this.loadingSet && this.locationsSet && this.freightSet
    },
    computedRouteFound () {
      return this.routeFound
    }
  },
  mounted () {
    this.cardIsLoading = true
    this.$utils.scrollToTop()
    this.init()

  },
  methods: {
    init () {
      //this.$store.dispatch('getSessionDataFromLocaleStorage')

    },
    locationsAreSet ({ locations }) {
      this.current.from = locations.from
      this.current.to = locations.to
      this.current.zone = locations.zone
      this.current.mapDistance = locations.mapDistance
      this.current.mapDistanceText = locations.mapDistanceText
      this.locationsSet = true

    },
    locationsAreUnset () {
      this.locationsSet = false
      this.rates = []
    },
    freightIsSet ({ freight }) {
      this.current.freight = freight
      this.freightSet = true

    },
    freightIsUnset () {
      this.freightSet = false
      this.rates = []
    },
    loadingIsSet ({ loading, unloading, loadingIsUnknown, unloadingIsUnknown }) {
      this.current.loading = loading
      this.current.unloading = unloading
      this.current.loadingIsUnknown = loadingIsUnknown ? true : false
      this.current.unloadingIsUnknown = unloadingIsUnknown ? true : false
      this.loadingSet = true

    },
    loadingIsUnset () {
      this.loadingSet = false
    },
    locationsBlur () {
      if (this.locationsSet && !this.allSet) {
        this.step = 2
      }
    },
    freightBlur () {
      if (this.freightSet && !this.allSet) {
        this.step = 3
      }
    },
    openMap () {
      this.card = 1
      this.$refs['gmap'].setRoute(this.current)
    },
    nextStep () {
      this.step++
    },
    prevStep () {
      if (this.card === 1) {
        this.card = 0
      } else {
        this.step--
      }
    },
    openInquiry () {
      this.$store.commit('searchSetDistance', this.current.mapDistance)
      this.$store.dispatch('saveSessionDataToLocaleStorage')
      this.$router.push('/inquiry')
    },
    onMapRouteNotFound () {
      this.doNoticeWarning('Zu der angegebenen Route konnte leider keine Strecke berechnet werden.')
      setTimeout(() => {
        this.card = 0
      }, 500)
    }
  }
}
</script>
