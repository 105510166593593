<template>
  <v-form autocomplete="off">
    <v-row>
      <v-col cols="12" class="pb-0 "><span class="overline">{{$t('main.pickup_in')}}</span></v-col>
    </v-row>
    <LocationSearch type-of-search="from" @complete="locationComplete" @uncomplete="locationUncomplete" />
    <v-row>
      <v-col cols="12" class="pb-0 "><span class="overline">{{$t('main.delivery_to')}}</span></v-col>
    </v-row>
    <LocationSearch type-of-search="to" @complete="locationComplete" @uncomplete="locationUncomplete" />
  </v-form>
</template>

<script>

import LocationSearch from '@/anfrage/components/input/LocationSearch'
export default {
  name: 'Location',
  components: { LocationSearch },
  data () {
    return {
      from: {},
      to: {},
      complete: {
        from: false,
        to: false
      },
      mapDistanceText: '',
      mapDistance: 0,
      mapQueryReset: {},
      zone: {},
      zoneReset: {}
    }
  },
  methods: {
    calculateDistance () {
      const mapQueryParameters = {
        origins: this.from.iso + ' ' + this.from.zip + ', ' + this.from.city,
        destinations: this.to.iso + ' ' + this.to.zip + ', ' + this.to.city
      }
      if ((this.mapQueryReset.origins !== mapQueryParameters.origins || this.mapQueryReset.destinations !== mapQueryParameters.destinations) && !this.$_.isUndefined(this.to.city) && !this.$_.isUndefined(this.from.city)) {
        this.mapQueryReset = Object.assign({}, mapQueryParameters)
        this.$http.ext.get('get_map_distance', { params: mapQueryParameters }).then(result => {
          if (result.status === 200) {
            let e = {}
            try {
              e = result.data.result.rows[0].elements[0]
            } catch (err) {
              e = {distance: {text: ''}}
            }
            this.mapDistanceText = e.distance.text
            this.mapDistance = parseFloat(e.distance.value / 1000) || 0
          }
          this.emitComplete()
        })
      }
    },
    locationComplete(props) {
      this[props.type] = this.$store.getters.search[props.type]
      this.complete[props.type] = true
      if(this.complete.from && this.complete.to && this.$_.isObject(this.to) && this.$_.isObject(this.from)) {
        this.calculateDistance()
      }
    },
    locationUncomplete(props) {
      this[props.type] = { }
      this.complete[props.type] = false
      this.emitUncomplete()
    },
    emitComplete () {
      this.$emit('complete', { locations: { from: this.from, to: this.to, mapDistanceText: this.mapDistanceText, mapDistance: this.mapDistance, zone: this.zone } })

    },
    emitUncomplete () {
      this.$emit('uncomplete')
    },
    onBlur() {
      this.$emit('blur')
    }
  }
}
</script>
