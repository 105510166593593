<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="12" class="d-flex justify-end">
          <logo-image :has-link="true" class="mr-4" />
        </v-col>
      </v-row>
    </v-container>

    <v-main >
      <router-view ></router-view>
    </v-main>
    <v-footer app padless class="hidden-sm-and-down">
      <v-toolbar>
        <v-toolbar-title  @click="openTPUrl" style="cursor: pointer">
          <logo-wzft-white v-if="this.$vuetify.theme.isDark" style="width: 150px"></logo-wzft-white>
          <logo-wzft-black v-else style="width: 150px"></logo-wzft-black>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-toolbar-items>
          <!--<v-select solo v-model="$root.$i18n.locale" @change="changeLocale" :items="['de', 'en']" dense class="mt-3 pr-4" style="max-width:100px" />-->
          <v-btn class="hidden-sm-and-down"  text>
            {{$t('main.imprint')}}
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
    </v-footer>
  </div>
</template>

<script>
import LogoWzftBlack from '@/_shared/svg/LogoWZFTBlack'
import LogoWzftWhite from '@/_shared/svg/LogoWZFTWhite'
import LogoImage from '@/_shared/components/LogoImage'


export default {
  name: 'AppIndex',
  components: { LogoWzftWhite, LogoWzftBlack, LogoImage },
  methods: {
    changeLocale() {
      sessionStorage.setItem('locale', this.$root.$i18n.locale)
    },
    openTPUrl() {
      window.open('https://transportpreis.digital/', '_blank')
    },
  }
}
</script>
