var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[_c('v-row',[_c('v-col',{staticClass:"subtitle-1",attrs:{"cols":"12"},domProps:{"innerHTML":_vm._s(_vm.$_.upperFirst(_vm.$t('main.pickup_and_delivery')) + ' ' + _vm.$tc('main.time', 2))}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":_vm.$t('main.time_period'),"items":_vm.computedExecutionTimes},model:{value:(_vm.executionTime),callback:function ($$v) {_vm.executionTime=$$v},expression:"executionTime"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:" d-flex d-sm-none align-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"subtitle-1",domProps:{"innerHTML":_vm._s(_vm.$_.capitalize(_vm.$t('main.pickup')))}})]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":true,"transition":"scale-transition","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('main.pickup') + ' ' + _vm.$t('main.from_time'),"prepend-icon":"mdi-calendar","readonly":"","rules":[_vm.$rules.required]},model:{value:(_vm.computedPickupDateFormatted),callback:function ($$v) {_vm.computedPickupDateFormatted=$$v},expression:"computedPickupDateFormatted"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"locale":_vm.calendarLocale,"first-day-of-week":"1","min":_vm.minDate,"allowed-dates":_vm.allowedDates},model:{value:(_vm.pickup),callback:function ($$v) {_vm.pickup=$$v},expression:"pickup"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"label":(_vm.$t('main.time_window')),"items":_vm.computedExecutionTimewindows},model:{value:(_vm.pickupExecutionTimewindow),callback:function ($$v) {_vm.pickupExecutionTimewindow=$$v},expression:"pickupExecutionTimewindow"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[(!_vm.pickupHasFixedTime)?_c('div',{staticClass:"d-flex "},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-clock-outline")]),_c('v-select',{attrs:{"items":_vm.hours,"label":_vm.$t('main.from_time')},model:{value:(_vm.pickupTimeFrom),callback:function ($$v) {_vm.pickupTimeFrom=$$v},expression:"pickupTimeFrom"}}),_c('v-icon',{staticClass:"mx-2"},[_vm._v("mdi-minus")]),_c('v-select',{attrs:{"items":_vm.hours,"label":_vm.$t('main.until')},model:{value:(_vm.pickupTimeTo),callback:function ($$v) {_vm.pickupTimeTo=$$v},expression:"pickupTimeTo"}})],1):_vm._e(),(_vm.pickupHasFixedTime)?_c('v-dialog',{ref:"timePickerStartDialog",attrs:{"return-value":_vm.pickupTime,"width":"440px","height":"540px","persistent":""},on:{"update:returnValue":function($event){_vm.pickupTime=$event},"update:return-value":function($event){_vm.pickupTime=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":_vm.$t('main.time'),"prepend-icon":"mdi-clock-outline","readonly":""},model:{value:(_vm.pickupTime),callback:function ($$v) {_vm.pickupTime=$$v},expression:"pickupTime"}},on))]}}],null,false,1821564713),model:{value:(_vm.timePickerStart),callback:function ($$v) {_vm.timePickerStart=$$v},expression:"timePickerStart"}},[(_vm.timePickerStart)?_c('v-time-picker',{attrs:{"full-width":"","landscape":"","ampm-in-title":"","format":"24hr","allowed-minutes":[0, 15, 30, 45],"color":"primary","header-color":"grey"},model:{value:(_vm.pickupTime),callback:function ($$v) {_vm.pickupTime=$$v},expression:"pickupTime"}}):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"block":"","tile":"","color":"primary"},on:{"click":function($event){return _vm.$refs.timePickerStartDialog.save(_vm.pickupTime)}}},[_vm._v("OK")])],1):_vm._e()],1)],1),_c('v-row',[_c('v-col',{staticClass:" d-flex d-sm-none align-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"subtitle-1"},[_vm._v(_vm._s(_vm.$_.capitalize(_vm.$t('main.delivery'))))])]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":true,"transition":"scale-transition","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('main.delivery') + ' ' + _vm.$t('main.until'),"prepend-icon":"mdi-calendar","readonly":"","rules":[_vm.$rules.required]},model:{value:(_vm.computedDeliveryDateFormatted),callback:function ($$v) {_vm.computedDeliveryDateFormatted=$$v},expression:"computedDeliveryDateFormatted"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"locale":_vm.calendarLocale,"first-day-of-week":"1","min":_vm.minDateDelivery,"allowed-dates":_vm.allowedDates},model:{value:(_vm.delivery),callback:function ($$v) {_vm.delivery=$$v},expression:"delivery"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"label":(_vm.$t('main.time_window')),"items":_vm.computedExecutionTimewindows},model:{value:(_vm.deliveryExecutionTimewindow),callback:function ($$v) {_vm.deliveryExecutionTimewindow=$$v},expression:"deliveryExecutionTimewindow"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[(!_vm.deliveryHasFixedTime)?_c('div',{staticClass:"d-flex "},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-clock-outline")]),_c('v-select',{attrs:{"items":_vm.hours,"label":_vm.$t('main.from_time')},model:{value:(_vm.deliveryTimeFrom),callback:function ($$v) {_vm.deliveryTimeFrom=$$v},expression:"deliveryTimeFrom"}}),_c('v-icon',{staticClass:"mx-2"},[_vm._v("mdi-minus")]),_c('v-select',{attrs:{"items":_vm.hours,"label":_vm.$t('main.until')},model:{value:(_vm.deliveryTimeTo),callback:function ($$v) {_vm.deliveryTimeTo=$$v},expression:"deliveryTimeTo"}})],1):_vm._e(),(_vm.deliveryHasFixedTime)?_c('v-dialog',{ref:"timePickerStartDialog",attrs:{"return-value":_vm.deliveryTime,"width":"440px","height":"540px","persistent":""},on:{"update:returnValue":function($event){_vm.deliveryTime=$event},"update:return-value":function($event){_vm.deliveryTime=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":_vm.$t('main.time'),"prepend-icon":"mdi-clock-outline","readonly":""},model:{value:(_vm.deliveryTime),callback:function ($$v) {_vm.deliveryTime=$$v},expression:"deliveryTime"}},on))]}}],null,false,1257051169),model:{value:(_vm.timePickerEnd),callback:function ($$v) {_vm.timePickerEnd=$$v},expression:"timePickerEnd"}},[(_vm.timePickerEnd)?_c('v-time-picker',{attrs:{"full-width":"","landscape":"","ampm-in-title":"","format":"24hr","allowed-minutes":[0, 15, 30, 45],"color":"primary","header-color":"grey"},model:{value:(_vm.deliveryTime),callback:function ($$v) {_vm.deliveryTime=$$v},expression:"deliveryTime"}}):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"block":"","tile":"","color":"primary"},on:{"click":function($event){return _vm.$refs.timePickerStartDialog.save(_vm.deliveryTime)}}},[_vm._v("OK")])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }